import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import SEO from '../components/seo';

const Quote = styled.div`
  font-size: 1.6em;
  text-align: center;
`;

const Intro = styled.div`
  font-size: 1.4em;
  text-indent: 2em;
  text-align: justify;
`;

const Index = ({ data }) => {
  const htmlQuote = () => ({
    __html: data.contentfulLandingPageIntroQuoteTextNode.childMarkdownRemark.html,
  });
  const htmlIntro = () => ({
    __html: data.contentfulLandingPageMainWritingTextNode.childMarkdownRemark.html,
  });

  return (
    <>
      <SEO description="Introduction" />
      <Quote dangerouslySetInnerHTML={htmlQuote()} />
      <Intro dangerouslySetInnerHTML={htmlIntro()} />
    </>
  );
};

Index.propTypes = {
  data: PropTypes.shape({
    contentfulLandingPageIntroQuoteTextNode: PropTypes.shape({
      childMarkdownRemark: PropTypes.shape({
        html: PropTypes.string.isRequired,
      }),
    }),
    contentfulLandingPageMainWritingTextNode: PropTypes.shape({
      childMarkdownRemark: PropTypes.shape({
        html: PropTypes.string.isRequired,
      }),
    }),
  }).isRequired,
};

export default Index;

export const query = graphql`
  query LandingPage {
    contentfulLandingPageMainWritingTextNode {
      childMarkdownRemark {
        html
      }
    }
    contentfulLandingPageIntroQuoteTextNode {
      childMarkdownRemark {
        html
      }
    }
  }
`;
